


import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action, State } from 'vuex-class';
import { Validations } from 'vuelidate-property-decorators';
import { TVuelidateRuleSet } from '@/_types/vuelitation-rule-set.type';
import { maxLength, url } from 'vuelidate/lib/validators';
import ValidationHelper from '@/_helpers/validation.helper';
import uploadPromoImage from '@/views/components/uploadImages/uploadPromoImage.vue';
import uploadPromoFiles from '@/views/components/uploadFiles/uploadPromoFiles.vue';
import uploadPhotosAndVideoPromopage from '@/views/components/uploadFiles/uploadPhotosAndVideoPromopage.vue';
import uploadProductImage from '@/views/components/uploadImages/uploadProductImage.vue';
import countryCityFields from '@/views/components/promoPage/countryCityFields.vue';
import chooseCompanyCategories from '@/views/components/popups/chooseCompanyCategories.vue';
import draggable from 'vuedraggable';
import PhoneInput from '@/_components/phone-input/phoneInput.vue';
import _cloneDeep from 'lodash.clonedeep';
import _isEqual from 'lodash.isequal';
import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
import { BroadcastType } from '@/_types/broadcasts/broadcast-type.enum';
import EventHelper from '@/_helpers/event.helper';
import { TimeStatus } from '@/_types/time-status.enum';
import { PromoContactRole } from '@/_types/promo-page/promo-contact-role.enum';
import IconSquareEdit from '@/_modules/icons/components/icon-square-edit.vue';
import IconSquareDelete from '@/_modules/icons/components/icon-square-delete.vue';
import IconCopy from '@/_modules/icons/components/icon-copy.vue';
import { TContact } from '@/_types/contact.type';
import { TEvent } from '@/_types/event.type';
import { TCodeList, TCode, TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TMeetingRoomConfig } from '@/_modules/meeting-rooms/types/meeting-room-config.type';
import { TDragOptions } from '@/_types/drag-options.type';
import { TAddContactByCodeParams } from '@/_api/contacts/contacts.api';
import { TPromoPageAttachedContacts } from '@/_types/promo-page/promo-page-attached-contacts.type';
import { TLeaveFunction } from '@/_modules/promo/types/edit-form-store-state.type';
import { TEventTag } from '@/_types/event-tag.type';
import { TCountry } from '@/_types/country.type';
import { TCity } from '@/_types/city.type';
import {TActivateTicketCodeParams} from '@/_modules/events/api/event/event.api';

type TCompanyProduct = {
  id?: number;
  photo_url: string;
  name: string;
  description: string;
  sorting: number;
  lang?: string;
}

type TEditCompanyProductOptions = {
  isEditProductVisible: boolean; // TODO: move this field into data or remove this todo
  product: TCompanyProduct;
  index: number;
}

// TODO: are all fields required?
type TCompanyEditFields = {
  website: string;
  external_id: string;
  logo_url: string;
  description: string;
  title: string;
  phone_number: string;
  country_id: number;
  city_id: number;
  addProductItem: TCompanyProduct;
}

type TUploaderExternalMethods = {
  showExternalImage: (imageUrl: string) => void;
  passClickIntoInput: () => void;
}

type TCoworkerForDeletion = {
  coworker: TPromoPageAttachedContacts;
  index: number;
}

type TEditCompanyTeamMember = {
  role?: string;
  contact?: TContact;
  displayTexts?: TEditCompanyTeamMemberExtraTexts;
  code?: string;
}

type TEditCompanyTeamMemberExtraTexts = {
    name?: string;
    surname?: string;
    position?: string;
    company?: string;
}

// TODO: see what can be done to improve this, if anything
// This type is TFile with some properties added for design and because of lack of experience.
type TEditCompanyFile = {
  id?: number;
  url: string;
  filename?: string;
  lang?: string;
  tempUploadedFlag?: boolean;
  name?: string;
}

@Component({
  components: {
    uploadPhotosAndVideoPromopage,
    uploadPromoFiles,
    uploadPromoImage,
    uploadProductImage,
    countryCityFields,
    chooseCompanyCategories,
    draggable,
    PhoneInput,
    IconCopy,
    IconSquareEdit,
    IconSquareDelete,
  },
})
export default class EditCompany extends Vue {

  @Validations()
  public readonly validations: TVuelidateRuleSet<TCompanyEditFields> = {
    companyEdit: {
      website: {
        maxLength: maxLength(250),
        url,
      },
      phone_number: {
        isRightLength(): boolean {
          // TODO: remove ts-ignore
          // @ts-ignore
          return ValidationHelper.isValidPhoneNumberLength(this.companyEdit.phone_number);
        },
        phoneValid: ValidationHelper.isValidPhoneNumber,
      },
    },
  };

  @Getter('promoPageStore/contact') contact: TContact;
  @Getter('promoPageStore/ownerContact') ownerContact: TContact;
  @Getter('_eventStore/event') event: TEvent;
  @Getter('_eventStore/eventTags') eventTags: TEventTag[];
  @Getter('editFormStore/hasUnsavedChanges') hasUnsavedChanges: boolean;
  @Getter('promoPageStore/isRemoveCoworkerConfirmed') isRemoveCoworkerConfirmed: boolean;
  @Getter('promoPageStore/promoPageCodeList') promoPageCodeList: TCodeList[] | void;
  @Getter('promoStore/myPromoPage') readonly promoPage: TPromoPage;
  @Getter('locationStore/countries') readonly countryList: TCountry[];
  @Getter('locationStore/citiesByCountryId') readonly cityList: (countryId: number) => TCity[];
  @Getter('locationStore/loading') readonly isLocationStoreLoading: boolean;
  @Getter('uploadProductsStore/getProductImageLoading') readonly isProductImageLoading: boolean;
  @Getter('promoStore/productsListStandalone') readonly productsListStandalone: TCompanyProduct[];
  @Getter('promoStore/editPromoPageLoading') readonly editPromoPageLoading: boolean;
  @Getter('promoStore/editPromoPageSuccess') readonly editPromoPageSuccess: boolean;

  @Action('editFormStore/showConfirmLeavePopup') showConfirmLeavePopup: (leave?: TLeaveFunction) => void;
  @Action('editFormStore/hideConfirmLeavePopup') hideConfirmLeavePopup: () => void;
  @Action('editFormStore/setHasUnsavedChanges') setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
  @Action('editFormStore/leave') leave: () => void;
  @Action('_eventStore/getEventTags') getEventTags: () => void;
  @Action('promoStore/setEditPromoPageSuccess') setEditPromoPageSuccess: (value: boolean) => void;
  @Action('_eventStore/activateTicketCode') activateTicketCode: (payload: TActivateTicketCodeParams) => Promise<boolean>;

  // TODO: replace @State with @Getters
  @State('promoStore/myPromoPageLoading') readonly isMyPromoPageLoading: boolean;
  @State('promoStore/promoOwnerId') readonly promoOwnerId: number;

  @Prop({type: Array, default: []})
  public readonly eventLanguages: string[];

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get meetingRoomConfig(): TMeetingRoomConfig {
    if (!this.eventId || !this.contact || !this.contact.id) {
      return null;
    }

    return {
      type: MeetingRoomType.BROADCAST,
      broadcastType: BroadcastType.PROMO_CONTACT,
      eventId: this.eventId,
      contactId: this.contact.id,
    };
  }

  public get externalId(): string {
    return (this.promoPage && this.promoPage.external_id) || null;
  }

  public get isLanguageSavingNeeded(): boolean {
    return !!(this.eventLanguages && (this.eventLanguages.length > 0) && this.currentEditLanguage);
  }

  public get currentEditLanguage(): string {
    if (this.eventLanguages && this.eventLanguages.length > 0) {
      if (this.selectedLanguageTab) {
        return this.selectedLanguageTab;
      }
      return this.eventLanguages[0];
    }

    return 'en';
  }

  /* We need this here, too because user can reach the page by URL */
  public get isAbleToSeeCompanyForm(): boolean {
    if (this.promoPage) {
      return !!this.promoPage.id;
    }
    return false;
  }

  public get isAddTeamMemberDisabled(): boolean {
    if (this.isAddTeamMemberButtonDisabled) {
      return true;
    }

    return this.companyEdit.phone_number.length < 8;
  }

  public get isPushProductButtonDisabled(): boolean {
    return this.isProductListUpdating
      || !this.companyEdit.addProductItem.photo_url
      || !this.companyEdit.addProductItem.description
      || !this.companyEdit.addProductItem.name;
  }

  public get companyWebsiteUrl(): string {
    return this.companyEdit.website || 'https://';
  }

  public set companyWebsiteUrl(url: string) {
    this.companyEdit.website = url === 'https://' ? '' : url;
  }

  public get codeList(): TCode[] {
    if (!this.promoPageCodeList || !this.promoPageCodeList.length) {
      return [];
    }

    const codeListForCurrentCompany: TCodeList = ((this.promoPageCodeList.find((item: TCodeList) => {
      return item.external_id === this.externalId;
    }) || {}));

    if (!codeListForCurrentCompany || !codeListForCurrentCompany.codes) {
      return [];
    }

    return (codeListForCurrentCompany.codes || []).filter(code => code.uses_left > 0);
  }

  public get isCopyCompanyDataFromEnglishButtonDisabled(): boolean {
    return !this.isFormDataEmpty;
  }

  public get isFormDataEmpty(): boolean {
    let result = true;
    if (!this.companyEdit) {
      return result;
    }
    const formDataKeys = Object.keys(this.companyEdit);
    const skipKeys = ['website', 'external_id', 'logo_url', 'country_id', 'city_id', 'addProductItem'];
    for (let i = 0; i < formDataKeys.length; i++) {
      const item = this.companyEdit[formDataKeys[i] as keyof TCompanyEditFields];
      let iterationResult: boolean;

      if (skipKeys.indexOf(formDataKeys[i]) >= 0) {
        continue;
      }

      if (Array.isArray(item)) {
        iterationResult = (item.length === 0);
      } else {
        iterationResult = !item;
      }

      if (iterationResult === false) {
        result = false;
        break;
      }
    }

    if (
      (this.promoPageFiles && this.promoPageFiles.length)
      || (this.promoPagePhotos && this.promoPagePhotos.length)
      || (this.promoPageProducts && this.promoPageProducts.length)
    ) {
      result = false;
    }

    return result;
  }

  public get standaloneIframeSrc(): string {
    return `${document.location.origin}/${this.$route.params.lang}/events/${this.eventId}/company/${this.externalId}`;
  }

  public get ownerUserId(): number {
    return this.ownerContact && this.ownerContact.user_id;
  }

  @Watch('code')
  private onCodeChange(): void {
    this.code = this.code.trim();
  }

  @Watch('contact', {deep: true, immediate: true})
  private onContactChange(): void {
    if (this.contact && this.contact.id) {
      this.getPromoPageByContactId();
    }
  }

  @Watch('promoPage', {deep: true})
  private onPromoPageChange(): void {
    this.initializePromoPage();
  }

  @Watch('editPromoPageSuccess')
  private onEditPromoPageSuccessChange(newVal: boolean): void {
    if (newVal) {
      this.removeEditPromoPageMessageTimeout = window.setTimeout(this.removeEditPromoPageMessage, 7000);
    }
  }

  @Watch('companyEdit', {deep: true})
  private onCompanyEditChange(): void {
    this.checkUnsavedChanges();
  }

  /* We need to clear the status message on language change.
   * After testing, a watcher seems to be better for this than v-if logic
   */
  @Watch('currentEditLanguage')
  private onCurrentEditLanguageChange(): void {
    this.removeEditPromoPageMessage();
  }

  @Watch('promoPageFiles')
  private onPromoPageFilesChange(): void {
    this.checkUnsavedChanges();
  }

  @Watch('promoPagePhotos')
  private onPromoPagePhotosChange(): void {
    this.checkUnsavedChanges();
  }

  @Watch('promoPageProducts')
  private onPromoPageProductsChange(): void {
    this.checkUnsavedChanges();
  }

  @Watch('isRemoveCoworkerConfirmed')
  private onIsRemoveCoworkerConfirmedChange(newVal: boolean): void {
    if (newVal && this.teamMemberToDelete.coworker) {
      this.deleteTeamMember(this.teamMemberToDelete.coworker, this.teamMemberToDelete.index);
    }
  }

  public readonly isPromoPageLoading: boolean;
  public editProductOptions: TEditCompanyProductOptions = {
    isEditProductVisible: false,
    product: null,
    index: null
  };
  public productDragOptions: TDragOptions = {
    animation: 200,
    group: 'productList',
    disabled: false,
  }
  public removeEditPromoPageMessageTimeout: number = -1;
  public selectedLanguageTab: string = '';
  public isProductListUpdating: boolean = false;
  public productDrag: boolean = false;
  public showCategoryChooser: boolean = false;
  public addTeamMemberError: boolean = false;
  public deleteTeamMemberError: boolean = false;
  public isAddTeamMemberButtonDisabled: boolean = false;
  public code: string = '';
  public addContactError: boolean = false;
  public copyCodeSuccess: string = ''; // TODO: better naming
  public copySuccessTimeOut: number = -1;
  public copyStandAloneIframeCodeSuccess: string = ''; // TODO: better naming
  public copyStandAloneIframeCodeTimeOut: number = -1;
  public accessKeyData: TAddContactByCodeParams = {
    eventId: null,
    externalId: '',
    code: '',
  };
  public phoneMask: string = '';
  public companyEdit: TCompanyEditFields = {
    website: '',
    external_id: '',
    logo_url: '',
    description: '',
    title: '',
    phone_number: '',
    country_id: 0,
    city_id: 0,
    addProductItem: {
      photo_url: '',
      name: '',
      description: '',
      sorting: 0,
    },
  };
  public oldCompanyEdit: TCompanyEditFields = { // Needed to compare with in watch/companyEdit
    website: '',
    external_id: '',
    logo_url: '',
    description: '',
    title: '',
    phone_number: '',
    country_id: 0,
    city_id: 0,
    addProductItem: {
      photo_url: '',
      name: '',
      description: '',
      sorting: 0,
    },
  };
  public promoPageFiles: TEditCompanyFile[] = [];
  public promoPagePhotos: TEditCompanyFile[] = [];
  public promoPageTags: TEventTag[] = [];
  public promoPageTagIds: number[] = [];
  public promoPageProducts: TCompanyProduct[] = [];
  public teamMembers: TEditCompanyTeamMember[] = [];
  public teamMemberToDelete: TCoworkerForDeletion = null;

  public mounted(): void {
    this.$store.dispatch('locationStore/requestCountries');
    this.$store.dispatch('promoPageStore/getPromoPagesWithCodes', { eventId: this.eventId });
    this.getEventTags();
  }

  private onBroadcastChoiceEmbedClick(): void {
    const meetingRoomConfig: TMeetingRoomConfig = this.meetingRoomConfig;
    if (!meetingRoomConfig) {
      return;
    }
    this.$store.dispatch('_eventStore/setEmbedCodeDialogConfig', meetingRoomConfig);
  }

  private onBroadcastChoiceZoomClick(): void {
    const eventTimeStatus: TimeStatus = EventHelper.getEventTimeStatus(this.event);
    if (eventTimeStatus === TimeStatus.PAST) {
      this.$store.dispatch('_eventStore/setIsBroadcastTimeCheckDialogVisible', true);
      return;
    }
    const meetingRoomConfig: TMeetingRoomConfig = this.meetingRoomConfig;
    if (!meetingRoomConfig) {
      return;
    }
    this.$store.dispatch('_eventStore/setZoomSettingsDialogConfig', meetingRoomConfig);
  }

  private onBroadcastChoiceOBSClick(): void {
    const eventTimeStatus: TimeStatus = EventHelper.getEventTimeStatus(this.event);
    if (eventTimeStatus === TimeStatus.PAST) {
      this.$store.dispatch('_eventStore/setIsBroadcastTimeCheckDialogVisible', true);
      return;
    }
    const meetingRoomConfig: TMeetingRoomConfig = this.meetingRoomConfig;
    if (!meetingRoomConfig) {
      return;
    }
    this.$store.dispatch('_eventStore/setObsSettingsDialogConfig', meetingRoomConfig);
  }

  private onBroadcastChoiceStreamYardClick(): void {
    const eventTimeStatus: TimeStatus = EventHelper.getEventTimeStatus(this.event);
    if (eventTimeStatus === TimeStatus.PAST) {
      this.$store.dispatch('_eventStore/setIsBroadcastTimeCheckDialogVisible', true);
      return;
    }
    const meetingRoomConfig: TMeetingRoomConfig = this.meetingRoomConfig;
    if (!meetingRoomConfig) {
      return;
    }
    this.$store.dispatch('_eventStore/setStreamYardSettingsDialogConfig', meetingRoomConfig);
  }

  private areLocalTagsSimilarToSaved(): boolean {
    // We don't have to compare some tag fields like isSelected, so we are filtering unneeded fields out here.
    const filteredLocalTags = _cloneDeep(this.promoPageTags).map(x => {
      return {
        id: x.id,
        name: x.name,
        parent_id: x.parent_id
      };
    });
    return _isEqual(filteredLocalTags, this.promoPage.tags);
  }

  private checkUnsavedChanges(): void {
    const result: boolean = (
      _isEqual(this.companyEdit, this.oldCompanyEdit)
      && (!this.hasCompanyWebsiteUrlChanged())
      && _isEqual(this.promoPageFiles, this.promoPage.files)
      && _isEqual(this.promoPagePhotos, this.promoPage.photos)
      && this.areLocalTagsSimilarToSaved()
      && _isEqual(this.promoPageProducts, this.promoPage.products));

    this.setHasUnsavedChanges(!result);
  }

  private hasCompanyWebsiteUrlChanged(): boolean {
    if (!this.companyWebsiteUrl || this.companyWebsiteUrl === 'https://') {
      return false;
    } else if (this.companyWebsiteUrl !== this.promoPage.website) {
      return true;
    }
    return false;
  }

  private initializePromoPage(): void {

    this.companyEdit.website = this.promoPage.website;
    this.companyEdit.description = this.promoPage.description;
    this.companyEdit.title = this.promoPage.title;
    this.companyEdit.logo_url = this.promoPage.logo_url;

    if (this.promoPage.country) {
      this.companyEdit.country_id = this.promoPage.country.id;
      this.requestCompanyCityList();
    }

    if (this.promoPage.city) {
      this.companyEdit.city_id = this.promoPage.city.id;
    }

    if (this.$refs.uploadCompanyLogo) {
      ((this.$refs.uploadCompanyLogo as unknown) as TUploaderExternalMethods).showExternalImage(this.promoPage.logo_url);
    }

    this.promoPageFiles = _cloneDeep(this.promoPage.files) as TEditCompanyFile[];
    this.promoPagePhotos = _cloneDeep(this.promoPage.photos) as TEditCompanyFile[];

    // Products list to display in the form
    if (this.promoPage.products) {
      this.promoPageProducts = (_cloneDeep(this.promoPage.products) || []) as TCompanyProduct[];
    }

    // Tags list to display in the form
    if (this.promoPage.tags) {
      this.promoPageTags = _cloneDeep(this.promoPage.tags) as TEventTag[]; // [{}, {}, ... {}] structure with tag data
      this.promoPageTagIds = this.promoPageTags.map((x) => x.id); // [1,9,3,43,]
    }

    if (this.promoPage.attached_contacts) {
      this.teamMembers = this.addTeamMemberDisplayInfoProperties(this.promoPage.attached_contacts.filter(item => {
        return !!item.role;
      }));
    }

    this.codeListRequest();

    // For unsaved changes comparison
    this.oldCompanyEdit = _cloneDeep(this.companyEdit);

  }

  // AW-1700
  private addTeamMemberDisplayInfoProperties(teamMembers: TPromoPageAttachedContacts[]): TEditCompanyTeamMember[] {
    return teamMembers.map(member => {
      return {
        ...member,
        displayTexts: {
          name: member.contact && member.contact.name ? this.truncateMemberInfoString(member.contact.name, 10) : '',
          surname: member.contact && member.contact.surname ? this.truncateMemberInfoString(member.contact.surname, 10) : '',
          position: member.contact && member.contact.company_position ? this.truncateMemberInfoString(member.contact.company_position, 10) : '',
          company: member.contact && member.contact.company_name ? this.truncateMemberInfoString(member.contact.company_name, 10) : '',
        }
      };
    });
  }

  public truncateMemberInfoString(input: string, toLength: number): string {
    if (input && input.length > toLength) {
      const truncatePostfix = '…';
      input = input.substr(0, toLength) + truncatePostfix;
    }
    return input;
  }

  private selectLanguageTab(langCode: string): void {
    const leaveAction: () => void = () => {
      // No re-requesting if the language is the same
      if (this.selectedLanguageTab !== langCode) {
        this.selectedLanguageTab = langCode;
        this.setHasUnsavedChanges(false);
        this.hideConfirmLeavePopup();
        this.getPromoPageByContactId();
      } else {
        this.selectedLanguageTab = langCode;
      }
      this.removeEditPromoPageMessage();
    };

    if (this.selectedLanguageTab !== langCode && this.hasUnsavedChanges) {
      this.showConfirmLeavePopup(leaveAction);
    } else {
      leaveAction();
    }

  }

  private onTagListUpdated(selectedTags: TEventTag[]): void {
    this.promoPageTags = selectedTags.filter(tag => tag.isSelected);
    this.promoPageTagIds = this.promoPageTags.map(tag => tag.id);
  }

  private toggleCategoryChooser(): void {
    this.showCategoryChooser = !this.showCategoryChooser;
  }

  private companyCountryChangeHandler(value: { country: number; city: number }): void {
    this.companyEdit.country_id = value.country;
    if (value.city) {
      this.companyEdit.city_id = value.city;
    } else {
      this.companyEdit.city_id = null;
    }
    this.requestCompanyCityList();
  }

  private requestCompanyCityList(): void {
    if (this.companyEdit.country_id) {
      this.$store.dispatch('locationStore/requestCities', this.companyEdit.country_id);
    }
  }

  public getTeamMemberAvatarBgImage(teamMember: TEditCompanyTeamMember): string {
    let photoUrl = require('@/assets/images/no-avatar-60x60.svg');
    if (teamMember && teamMember.contact && teamMember.contact.photo_url) {
      photoUrl = teamMember.contact.photo_url;
    }
    return 'url(' + photoUrl + ')';
  }

  private getClonedPromoPagePhotos(): TEditCompanyFile[] {
    return _cloneDeep(this.promoPagePhotos);
  }

  private removeEditPromoPageMessage(): void {
    this.setEditPromoPageSuccess(false);
    window.clearTimeout(this.removeEditPromoPageMessageTimeout);
  }

  private onCompanyLogoUpdate(imageUrl: string): void {
    this.companyEdit.logo_url = imageUrl;
  }

  private onCompanyFilesUpdate(data: TEditCompanyFile[]): void {
    this.promoPageFiles = data || [];
    this.checkUnsavedChanges();
  }

  private onProductImageUpdate(data: string): void {
    this.companyEdit.addProductItem.photo_url = data || '';
    this.checkUnsavedChanges();
  }

  private onCompanyPhotosAndVideosUpdate(data: TEditCompanyFile[]): void {
    this.promoPagePhotos = data || [];
    this.checkUnsavedChanges();
  }

  /* ONLY PUSHES INTO THE LOCAL PRODUCT LIST.
   * Proper saving is done in saveProducts
   */
  private onAddProductClick(): void {
    const productBeingAdded: TCompanyProduct = { ...this.companyEdit.addProductItem };

    productBeingAdded.sorting = this.promoPageProducts.length;

    this.promoPageProducts.push(productBeingAdded);

    this.clearAddProductItem();

    ((this.$refs.promoPageProductImageUploader as unknown) as TUploaderExternalMethods).showExternalImage('');
  }

  private replaceProductItem(): void {

    const productBeingAdded: TCompanyProduct = { ...this.companyEdit.addProductItem };
    productBeingAdded.sorting = this.promoPageProducts.length;

    const id: number = this.promoPageProducts[this.editProductOptions.index].id;
    const image: string = this.promoPageProducts[this.editProductOptions.index].photo_url;

    this.promoPageProducts[this.editProductOptions.index] = productBeingAdded;
    this.promoPageProducts[this.editProductOptions.index].id = id;

    if (!this.companyEdit.addProductItem.photo_url) {
      this.promoPageProducts[this.editProductOptions.index].photo_url = image;
    }

    this.clearAddProductItem();

    ((this.$refs.promoPageProductImageUploader as unknown) as TUploaderExternalMethods).showExternalImage('');
    this.editProductOptions.isEditProductVisible = false;
  }

  private clearAddProductItem(): void {
    this.companyEdit.addProductItem = {
      photo_url: '',
      name: '',
      description: '',
      sorting: 0,
    };
  }

  private async onDeleteProductClick(product: TCompanyProduct, index: number): Promise<void> {
    const isProductInDatabase = !!(product.id);
    if (isProductInDatabase) {
      await this.$store.dispatch('promoStore/deletePromoPageProduct', {
        event_id: this.$route.params.eventId,
        external_id: this.externalId,
        product_id: product.id
      });
    }

    this.promoPageProducts.splice(index, 1);
  }

  private async editProduct(product: TCompanyProduct, index: number): Promise<void> {
    this.editProductOptions.isEditProductVisible = true;
    this.editProductOptions.product = product;
    this.editProductOptions.index = index;

    this.companyEdit.addProductItem.name = this.promoPageProducts[index].name;
    this.companyEdit.addProductItem.description = this.promoPageProducts[index].description;
  }

  private editProductPhoto(): void {
    ((this.$refs.promoPageProductImageUploader as unknown) as TUploaderExternalMethods).passClickIntoInput();
  }

  private onDeleteTeamMemberClick(teamMember: TPromoPageAttachedContacts, index: number): void {
    this.$store.dispatch('promoPageStore/setRemoveCoworkerPopupVisible', true);
    this.teamMemberToDelete = { coworker: teamMember, index };
  }

  private async deleteTeamMember(teamMember: TPromoPageAttachedContacts, index: number): Promise<void> {
    if (!teamMember.contact.user.id || teamMember.role === PromoContactRole.OWNER) {
      return;
    }

    this.addTeamMemberError = false;
    this.deleteTeamMemberError = false;

    const payload: any = {
      event_id: this.$route.params.eventId,
      external_id: this.externalId,
      user_id: teamMember.contact.user.id,
    };

    const result: any = await this.$store.dispatch('promoStore/deleteUserPromoPage', payload);

    if (!result || result.status !== 202) {
      this.deleteTeamMemberError = true;
    } else if (typeof index !== 'undefined') {
      this.teamMembers.splice(index, 1);
    }

    await this.$store.dispatch('promoPageStore/setRemoveCoworkerPopupVisible', false);
  }

  private async deleteCode(code: TCode): Promise<void> {
    const payload = {
      eventId: this.$route.params.eventId,
      externalId: this.externalId,
      codeId: code.id
    };

    await this.$store.dispatch('promoPageStore/deletePromoPageCode', payload);
    await this.codeListRequest();

  }

  private getPromoPageByContactId(): void {
    const payload: any = {
      event_id: this.$route.params.eventId,
      contact_id: this.contact.id,
    };

    if (this.currentEditLanguage) {
      payload.acceptLanguage = this.currentEditLanguage;
    }

    this.$store.dispatch('promoStore/getMyPromoPageContactId', payload);
  }

  // TODO: rewrite me
  private async codeListRequest(): Promise<void> {
    // await this.$store.dispatch('promoPageStore/getPromoPagesWithCodes', {eventId: this.eventId});
    await this.$store.dispatch('promoPageStore/getPromoPageCodes', this.eventId);
    const membersWithoutCode = this.teamMembers.filter(item => !!item.displayTexts);

    if (this.codeList && this.codeList.length) {
      this.teamMembers = [...membersWithoutCode, ...(this.codeList as TEditCompanyTeamMember[])];
    } else {
      if (!this.teamMembers.find(item => item.code === this.accessKeyData.code) && this.accessKeyData.code) {
        this.teamMembers.push({ code: this.accessKeyData.code });
      }
    }
  }

  /* Main form save handler
   */
  private async submitCompanyForm(): Promise<void> {
    this.$v.companyEdit.$touch();
    if (this.$v.companyEdit.$pending || this.$v.companyEdit.$invalid) {
      return;
    }

    if (this.isPromoPageLoading || this.$store.state.uploadPromopageFilesStore.loading) {
      return;
    }

    await this.saveCompanyMainFields();
    await this.saveCompanyDocuments();
    await this.saveCompanyPhotosAndVideos();
    await this.saveProducts();

    this.setCompanyFormSaved();
  }

  private async saveCompanyMainFields(): Promise<void> {
    const payload: any = {
      event_id: this.$route.params.eventId,
      external_id: this.externalId,
      website: this.companyEdit.website,
      logo_url: this.companyEdit.logo_url,
      description: this.companyEdit.description,
      title: this.companyEdit.title,
      country_id: this.companyEdit.country_id,
      city_id: this.companyEdit.city_id,
      tags: this.promoPageTags.map(x => x.id)
    };

    // Adding the lang param if we need to save it for multilang events
    if (this.isLanguageSavingNeeded) {
      payload.lang = this.currentEditLanguage;
    }

    await this.$store.dispatch('promoStore/updatePromoPage', payload);
  }

  private async saveCompanyDocuments(): Promise<void> {
    if (!this.promoPageFiles) {
      return;
    }

    let fileIndex = 0;

    for (const file of this.promoPageFiles) {
      // If a file has an id, we do not need to resend it
      // Also checking for existence of url field — it is required on backend, won't send without it anyway
      if (!file || file.id || file.tempUploadedFlag || !file.url) {
        fileIndex++;
        continue;
      }

      const filePayload: any = {
        data: {
          url: file.url,
          filename: file.name
        },
        event_id: this.$route.params.eventId,
        external_id: this.externalId
      };

      // Language of data to be saved, if needed
      if (this.isLanguageSavingNeeded) {
        filePayload.data.lang = this.currentEditLanguage;
      }

      await this.$store.dispatch('promoStore/putFileUrlPromoPage', filePayload).then(() => {
        // Setting the local flag for each new object in that array. Since we have an SPA,
        // a user can stay on the page after submitCompanyForm(), which leaves new files
        // without an file.id field which we can't yet update from the backend
        // using .splice because of https://vuejs.org/v2/guide/reactivity.html#For-Arrays
        // TODO: update file objects with id after saving on backend, if that is possible
        this.promoPageFiles.splice(fileIndex, 1, {
          ...file,
          tempUploadedFlag: true
        });
      });

    }

  }

  private async saveCompanyPhotosAndVideos(): Promise<void> {
    if (!this.promoPagePhotos) {
      return;
    }

    for (let i = 0; i < this.promoPagePhotos.length; i++) {
      // If a file has an id, we do not need to resend it
      // Also checking for existence of url field — it is required on backend, won't send without it anyway
      if (this.promoPagePhotos[i].id || this.promoPagePhotos[i].tempUploadedFlag || !this.promoPagePhotos[i].url) {
        continue;
      }

      const photoPayload: any = {
        data: {
          url: this.promoPagePhotos[i].url,
          filename: this.promoPagePhotos[i].name
        },
        event_id: this.$route.params.eventId,
        external_id: this.externalId
      };

      // Language of data to be saved, if needed
      if (this.isLanguageSavingNeeded) {
        photoPayload.data.lang = this.currentEditLanguage;
      }

      await this.$store.dispatch('promoStore/putPhotoVideoUrlPromoPage', photoPayload).then(() => {
        // Setting the local flag for each new object in that array. Since we have an SPA,
        // a user can stay on the page after submitCompanyForm(), which leaves new files
        // without an file.id field which we can't yet update from the backend
        // using .splice because of https://vuejs.org/v2/guide/reactivity.html#For-Arrays
        // TODO: update file objects with id after saving on backend, if that is possible at the moment
        this.promoPagePhotos.splice(i, 1, {
          ...this.promoPagePhotos[i],
          tempUploadedFlag: true
        });
      });
    }
  }

  // TODO: refactor
  private async saveProducts(): Promise<void> {

    if (!this.promoPageProducts) {
      return;
    }

    const resultingPromises: Promise<any>[] = [];

    this.isProductListUpdating = true;

    this.promoPageProducts.forEach((item, index) => {
      // Sorting. Backend ignores the zero, so we make sorting 1-based
      item.sorting = index + 1;

      // If id is present, we PATCH, otherwise we PUT
      let method = 'put';
      if (item.id) {
        method = 'patch';
      }

      const productInfoToSend: TCompanyProduct = {
        photo_url: item.photo_url,
        name: item.name,
        description: item.description,
        sorting: item.sorting,
      };

      // Language of data to be saved, if needed
      if (this.isLanguageSavingNeeded && method === 'put') {
        productInfoToSend.lang = this.currentEditLanguage;
      }

      const payload: any = { // TODO: type, store refactoring to TS
        data: productInfoToSend,
        event_id: this.$route.params.eventId,
        external_id: this.externalId
      };

      switch (method) {
        case 'put':
          resultingPromises.push(this.$store.dispatch('promoStore/putPromoPageProduct', payload));
          break;
        case 'patch':
          payload.product_id = item.id;
          resultingPromises.push(this.$store.dispatch('promoStore/patchPromoPageProduct', payload));
          break;
        default:
        /* ignore */
      }

    });

    // Updates only the product list, not touching this.promoPage to avoid all the @Watch
    await Promise.all(resultingPromises).then(async () => {
      await this.$store.dispatch('promoStore/getProductsFromPromoPage', {
        eventId: this.$route.params.eventId,
        externalId: this.externalId,
        lang: this.currentEditLanguage,
      });
      this.promoPageProducts = _cloneDeep(this.productsListStandalone);
    });

    this.isProductListUpdating = false;

  }

  private setCompanyFormSaved(): void {
    this.oldCompanyEdit = _cloneDeep(this.companyEdit);
    this.setHasUnsavedChanges(false);
  }

  private async addContact(): Promise<void> {
    if (!this.code) {
      return;
    }

    const activateTicketCodeRequestResult: boolean = await this.activateTicketCode({
      eventId: this.eventId,
      code: this.code
    });

    if (activateTicketCodeRequestResult !== true) {
      this.addContactError = true;
      this.code = '';
      return;
    }

    this.getPromoPageByContactId();
  }

  private async addTeamMember(): Promise<void> {
    this.accessKeyData.eventId = this.eventId;
    this.accessKeyData.externalId = this.externalId;
    this.accessKeyData.code = `${(~~(Math.random() * 1e8)).toString(19)}${this.getRandomChars()}`;

    const result: boolean = await this.$store.dispatch('contactsStore/addContactByCode', this.accessKeyData);

    await this.codeListRequest();

    if (!result) {
      this.addTeamMemberError = true;
    } else {
      this.addTeamMemberError = false;
      this.deleteTeamMemberError = false;
    }

    // this.getPromoPageByContactId()
  }

  public getRandomChars(): string {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 3; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  private copy(): void {
    window.clearTimeout(this.copySuccessTimeOut);
    const dummyTextarea: HTMLTextAreaElement = document.createElement('textarea');
    document.body.appendChild(dummyTextarea);
    dummyTextarea.value = this.accessKeyData.code;
    dummyTextarea.select();
    const isSuccessfullyCopied: boolean = document.execCommand('copy');
    document.body.removeChild(dummyTextarea);

    if (isSuccessfullyCopied) {
      this.copyCodeSuccess = `${this.$t('organizerCabinet.codeHasBeenCopied')}: ${this.accessKeyData.code}`;
      this.copySuccessTimeOut = window.setTimeout(() => {
        this.copyCodeSuccess = '';
      }, 3000);
    }
    this.accessKeyData.code = '';
  }

  private copyStandAloneIframeCode(): void {
    window.clearTimeout(this.copyStandAloneIframeCodeTimeOut);
    (this.$refs['standalone-company-textarea'] as HTMLTextAreaElement).select();
    if (document.execCommand('copy')) {
      this.copyStandAloneIframeCodeSuccess = `${this.$t('promo.company.edit.standAloneCompanyCode.copySuccess')}`;
      this.copyStandAloneIframeCodeTimeOut = window.setTimeout(() => {
        this.copyStandAloneIframeCodeSuccess = '';
      }, 3000);
    }
  }

  private async copyCompanyDataFromEnglish(): Promise<void> {
    if (this.isCopyCompanyDataFromEnglishButtonDisabled) {
      return;
    }

    const payload: any = {
      event_id: this.$route.params.eventId,
      contact_id: this.contact.id,
    };

    if (this.currentEditLanguage) {
      payload.acceptLanguage = 'en';
    }

    await this.$store.dispatch('promoStore/getMyPromoPageContactId', payload);

    this.clearEntityIdsAfterCopyFromEnglish();
  }

  private clearEntityIdsAfterCopyFromEnglish(): void {
    this._doCleanupRoutines(this.promoPageProducts);
    this._doCleanupRoutines(this.promoPagePhotos, true);
    this._doCleanupRoutines(this.promoPageFiles, true);
  }

  private _doCleanupRoutines(cleanupTarget: any[], isTempUploadedFlagRemovalNeeded?: boolean): void {
    if (cleanupTarget && Array.isArray(cleanupTarget) && cleanupTarget.length) {
      // N.B. We mutate items of cleanupTarget, using JavaScripts passing of Arrays and objects by reference
      cleanupTarget.forEach(item => {
        if (item.id) {
          delete item.id;
        }
        if (isTempUploadedFlagRemovalNeeded && Object.prototype.hasOwnProperty.call(item, 'tempUploadedFlag')) {
          delete item.tempUploadedFlag;
        }
      });
    }
  }

}
