


import {Component, Prop, Watch, Vue, Ref} from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import _cloneDeep from 'lodash.clonedeep';
import _isEqual from 'lodash.isequal';
import { email, maxLength, required, url } from 'vuelidate/lib/validators';
import uploadImage from '@/views/components/uploadImages/uploadImage.vue';
import uploadFiles from '@/views/components/uploadFiles/uploadFiles.vue';
import IconCloseQrPopup from '@/_modules/icons/components/qrpopup/icon-close-qr-popup.vue';
import {TContactTag} from '@/_types/contact-tag.type';
import {TLeaveFunction} from '@/_modules/promo/types/edit-form-store-state.type';
import {Validations} from 'vuelidate-property-decorators';
import {TVuelidateRuleSet} from '@/_types/vuelitation-rule-set.type';
import {TContact} from '@/_types/contact.type';
import {TCountry} from '@/_types/country.type';
import {TUser} from '@/_types/user.type';
import {TEvent} from '@/_types/event.type';
import {TProduct} from '@/_types/promo-page/product.type';
import {TPromoPage} from '@/_types/promo-page/promo-page.type';
import {TFile} from '@/_types/file.type';
import {TPutContactFileParams} from '@/_api/contacts/contacts.api';

const CONTACT_TAG_SELECTION_LIMIT = 10;

interface IUserPhotoUploader {
  showExternalImage: (photoUrl: string) => void;
}

@Component({
  components: {
    uploadImage,
    uploadFiles,
    IconCloseQrPopup,
  },
})
export default class EditContact extends Vue {

  @Ref('standalone-contact-textarea') standaloneContactTextarea: HTMLTextAreaElement;

  @Getter('_eventStore/event') public event: TEvent;
  @Getter('_eventStore/eventContactTags') public eventContactTags: TContactTag[];
  @Getter('_userStore/user') public userInfo: TUser;
  @Getter('promoStore/promoOwnerId') promoOwnerId: number;
  @Getter('promoStore/myPromoPage') myPromoPage: TPromoPage;
  @Getter('promoStore/editPromoPageSuccess') editPromoPageSuccess: boolean;
  @Getter('promoStore/editPromoPageLoading') editPromoPageLoading: boolean;
  @Getter('promoStore/productsListStandalone') productListStandalone: TProduct[];
  @Getter('editFormStore/hasUnsavedChanges') public hasUnsavedChanges: boolean;
  @Getter('promoPageStore/contact') public myself: TContact;
  @Getter('promoPageStore/isContactLoading') public isContactLoading: boolean;

  @Action('contactsStore/putContactFile') putContactFile: (fileInfo: TPutContactFileParams) => Promise<void>;
  @Action('editFormStore/setHasUnsavedChanges') public setHasUnsavedChanges: (newVal: boolean) => void;
  @Action('editFormStore/showConfirmLeavePopup') public showConfirmLeavePopup: (leaveFunction?: TLeaveFunction) => void;
  @Action('editFormStore/hideConfirmLeavePopup') public hideConfirmLeavePopup: () => void;
  @Action('editFormStore/leave') public leave: () => void;
  @Action('locationStore/requestCountries') public requestLocationStoreCountries: () => Promise<TCountry[]>;
  @Action('promoPageStore/editContact') editContact: (params: any) => Promise<boolean>; // TODO: type for params
  @Action('promoPageStore/getContact') public getContact: (payload: { eventId: number; acceptLanguage: string }) => Promise<void>;
  @Action('promoPageStore/setContactPhotoURL') setContactPhotoURL: (newImageUrl: string) => Promise<void>;

  @Prop({
    type: Array,
    default: (): string[] => {
      return [];
    }
  })
  private eventLanguages: string[];

  public standaloneContactUrlCopySuccessMessage: string = '';
  public isLoading: boolean = false;
  public isContactLoadingWatcherBlocked: boolean = true;
  public selectedLanguageTab: string = '';
  public isEditSuccessMessageShown: boolean = false;
  public localContact: TContact = {
    id: 0,
    event_id: 0,
    name: '',
    surname: '',
    email: '',
    photo_url: '',
    country: '',
    city: '',
    company_name: '',
    company_website: '',
    company_position: '',
    company_description: '',
    files: [],
    tags: [],
    phone: '',
    facebook: '',
    linked_in: '',
    skype: '',
    telegram: '',
    twitter: '',
    viber: '',
    wechat: '',
  };
  public oldContact: TContact = { // Needed to compare with in watch/contact
    id: 0,
    event_id: 0,
    name: '',
    surname: '',
    email: '',
    photo_url: '',
    country: '',
    city: '',
    company_name: '',
    company_website: '',
    company_position: '',
    company_description: '',
    files: [],
    tags: [],
    phone: '',
    facebook: '',
    linked_in: '',
    skype: '',
    telegram: '',
    twitter: '',
    viber: '',
    wechat: '',
  };
  public isContactTagSelectorVisible: boolean = false;
  public eventContactTagsLocal: TContactTag[] = [];
  public copyStandaloneContactTimeOut: number = 0;

  public get contactId(): number {
    return (this.myself && this.myself.id) || null;
  }

  public set contactCompanyWebsite(url: string) {
    this.localContact.company_website = url === 'https://' ? '' : url;
  }

  public get contactCompanyWebsite(): string {
    return this.localContact.company_website || 'https://';
  }

  public set contactLinkFacebook(newVal: string) {
    if (!newVal) {
      this.localContact.facebook = '';
      return;
    }
    const pattern = /^(?:(?:http|https):\/\/)?(?:www\.)?(facebook\.com)|(fb\.me)\/(?:\w*#!\/)?(?:pages\/)?(?:[?\w-]*\/)?(?:profile\.php\?id=(?=\d.*))?([\w-]*)?$/;
    const isLikeFacebookHandle = (/[a-zA-Z0-9_-]+/).test(newVal);
    if (isLikeFacebookHandle && newVal.indexOf('https://') < 0) {
      newVal = 'https://fb.me/' + newVal;
    }

    if (newVal.indexOf('http://') !== 0 && newVal.indexOf('https://') !== 0) {
      newVal = 'https://' + newVal;
    }

    if (pattern.test(newVal)) {
      this.localContact.facebook = newVal.replace('http:', 'https:');
    }
  }

  public get contactLinkFacebook(): string {
    return this.localContact.facebook || '';
  }

  public set contactLinkLinkedIn(newVal: string) {
    if (!newVal) {
      this.localContact.linked_in = '';
      return;
    }
    const pattern = /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^/]+\/([^/\s_.,;:{}()[\]$!*^+=~`'"€§′ѳѣ™®≈£₽„“”‘’…»«−ß↓©×¹²³↑∞←→—≠]+\/?){1,}))$/;
    const isLikeLinkedInHandle = (/^[^/\s_.,;:{}()[\]$!*^+=~`'"€§′ѳѣ™®≈£₽„“”‘’…»«−ß↓©×¹²³↑∞←→—≠]+$/).test(newVal);
    if (isLikeLinkedInHandle) {
      newVal = 'https://www.linkedin.com/in/' + newVal;
    }

    if (newVal.indexOf('http://') !== 0 && newVal.indexOf('https://') !== 0) {
      newVal = 'https://' + newVal;
    }

    if (pattern.test(newVal)) {
      this.localContact.linked_in = newVal.replace('http:', 'https:');
    }
  }

  public get contactLinkLinkedIn(): string {
    return this.localContact.linked_in || '';
  }

  public set contactLinkSkype(newVal: string) {
    if (!newVal) {
      this.localContact.skype = '';
      return;
    }
    const pattern = /^https:\/\/join\.skype\.com\/invite\/[a-zA-Z0-9]+\/?$/;
    if (pattern.test(newVal.trim())) {
      this.localContact.skype = newVal.trim();
    }
  }

  public get contactLinkSkype(): string {
    return this.localContact.skype || '';
  }

  public set contactLinkTelegram(newVal: string) {
    if (!newVal) {
      this.localContact.telegram = '';
      return;
    }
    const pattern = /^(?:|(https?:\/\/)?(|www)[.]?((t|telegram)\.me)\/)[a-zA-Z0-9_]{4,32}$/;
    const isLikeTelegramHandle = (/^(@)?[a-zA-Z0-9_]{1,32}$/).test(newVal);
    if (isLikeTelegramHandle && newVal.indexOf('https://') < 0) {
      newVal = 'https://t.me/' + newVal.replace(/@/g, '');
    }

    if (newVal.indexOf('http://') !== 0 && newVal.indexOf('https://') !== 0) {
      newVal = 'https://' + newVal;
    }

    if (pattern.test(newVal)) {
      this.localContact.telegram = newVal.replace('http:', 'https:');
    }
  }

  public get contactLinkTelegram(): string {
    return this.localContact.telegram || '';
  }

  public set contactLinkTwitter(newVal: string) {
    if (!newVal) {
      this.localContact.twitter = '';
      return;
    }
    const pattern = /https?:\/\/(?:www\.)?twitter\.com\/@?([a-zA-Z0-9_]+)/;
    const isLikeTwitterHandle = (/^@?[a-zA-Z0-9_]+$/).test(newVal);
    if (isLikeTwitterHandle && newVal.indexOf('https://') !== 0) {
      newVal = 'https://twitter.com/' + newVal.replace(/@/g, '');
    }

    if (newVal.indexOf('http://') !== 0 && newVal.indexOf('https://') !== 0) {
      newVal = 'https://' + newVal;
    }

    if (pattern.test(newVal)) {
      this.localContact.twitter = newVal.replace('http:', 'https:');
    }
  }

  public get contactLinkTwitter(): string {
    return this.localContact.twitter;
  }

  public set contactLinkViber(newVal: string) {
    if (!newVal) {
      this.localContact.viber = '';
      return;
    }
    // viber://contact?number=380992799120
    const isLikeViberHandle = (/^\+?[0-9]{12}$/).test(newVal);
    if (isLikeViberHandle) {
      this.localContact.viber = '+' + newVal.replace('+', '');
    }
  }

  public get contactLinkViber(): string {
    return this.localContact.viber || '';
  }

  public set contactLinkWechat(newVal: string) {
    if (!newVal) {
      this.localContact.wechat = '';
      return;
    }
    const pattern = /^weixin:\/\/dl\/chat\?[a-zA-Z0-9_]+$/;
    const isLikeWechatHandle = (/^[a-zA-Z0-9_]$/).test(newVal);
    if (isLikeWechatHandle) {
      newVal = 'weixin://dl/chat?' + newVal;
    }

    if (pattern.test(newVal)) {
      this.localContact.wechat = newVal;
    }
  }

  public get contactLinkWechat(): string {
    return this.localContact.wechat || '';
  }

  public get telegramMaxLength(): number {
    const longDomain = 'https://telegram.me/';
    const shortDomain = 'https://t.me/';
    if (this.contactLinkTelegram.indexOf(shortDomain) === 0) {
      return shortDomain.length + 32;
    }
    return longDomain.length + 32;
  }

  public viberMaxLength: number = 13;

  public get isContactTagSelectionLimitReached(): boolean {
    const selectedTagsQuantity = this.eventContactTagsLocal.filter(item => (item as any).isSelected).length;
    return CONTACT_TAG_SELECTION_LIMIT <= selectedTagsQuantity;
  }

  public get eventContactTagsLocalSelected(): TContactTag[] {
    // Not cloning because we do not mutate it in any way yet. Feel free to _cloneDeep if requirements change.
    return this.eventContactTagsLocal.filter(item => (item as any).isSelected);
  }

  public get currentEditLanguage(): string {
    if (this.eventLanguages && this.eventLanguages.length) {
      return this.selectedLanguageTab || this.eventLanguages[0];
    }

    // TODO: is it ok? Maybe not.
    // When a user visits by URL, say, ru/events/365/promo/edit/company
    // they see the promopage data in Russian, but the selected tab is English
    // This can save bad data into the DB.
    // Because of this, I hardcoded a default language here so that
    // this.getPromopageContactId always has some language in this.currentEditLanguage
    return 'en';
  }

  public get isShareContactUrlVisible(): boolean {
    const hasOrigin = !!(window && window.location && window.location.origin);
    const isEventsWalletDomain = hasOrigin && window.location.origin.indexOf('eventswallet') >= 0;
    const isLocalDomain = hasOrigin && window.location.origin.indexOf('local') >= 0;
    return hasOrigin && (isEventsWalletDomain || isLocalDomain);
  }

  public isCustomHostname(): boolean {
    return /^(dev-web.eventswallet)|(local.eventswallet)|(app.eventswallet)|(www.eventswallet)/.test(window.location.host) !== true;
  }

  public getOpenGraphOrigin(): string {
    if (this.isCustomHostname()) {
      const domainZone = location.hostname.split('.').pop();
      return 'https://www.eventswallet.' + domainZone;
    }

    return window.location.origin.replace('app.', 'www.');
  }

  public get standaloneContactUrl(): string {
    if (!this.localContact || !this.contactId || !this.eventId || !this.event || !this.isShareContactUrlVisible) {
      return '';
    }
    const eventSlug = this.event.slug;
    return `${this.getOpenGraphOrigin()}/${this.$route.params.lang}/events/${eventSlug}/contact/${this.localContact.id}`;
  }

  @Watch('userInfo', { deep: true, immediate: true })
  private onUserInfoChange(): void {
    this.callContactInfo();
  }

  @Watch('myself', { deep: true })
  private onMyselfChange(newVal: TContact): void {
    if (!newVal) {
      return;
    }
    this.setContactData();
    if (this.$refs.userPhotoUploader && this.myself.photo_url) {
      (this.$refs.userPhotoUploader as unknown as IUserPhotoUploader).showExternalImage(this.myself.photo_url);
    }
  }

  @Watch('localContact', { deep: true })
  private onLocalContactChange(): void {
    this.setHasUnsavedChanges(!_isEqual(this.localContact, this.oldContact));
  }

  @Watch('eventContactTags')
  private onEventContactTagsChange(): void {
    let selectedTagIds: number[] = [];
    if (this.localContact && this.localContact.tags) {
      selectedTagIds = this.localContact.tags.map(item => item.id);
    }
    this.eventContactTagsLocal = _cloneDeep(this.eventContactTags).map(item => {
      (item as any).isSelected = !!selectedTagIds.find(selectedItem => item.id === selectedItem);
      return item;
    });
  }

  @Watch('isContactLoading', { immediate: true })
  private onIsContactLoadingChange(newVal: boolean, oldVal: boolean): void {
    if (this.isContactLoadingWatcherBlocked) {
      return;
    }
    if (!newVal && oldVal) {
      this.refreshContactData();
      this.isContactLoadingWatcherBlocked = true;
    }
  }

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public mounted(): void {
    this.localContact.event_id = this.eventId; // see type TEditContactActionParams
    this.requestLocationStoreCountries();
    // TODO: refactor. maybe research the getContactPromoPageRequest.cancel() way — check side-bar-right, bad effects of cancel() can be there.
    if (this.isContactLoading) { // AW-2671. Could also be done by getContactPromoPageRequest.cancel() but that creates other problems.
      this.isContactLoadingWatcherBlocked = false;
    } else {
      this.refreshContactData();
    }
    this.getContactTags();
  }

  public async refreshContactData(): Promise<void> {
    await this.callContactInfo();
    this.setContactData();
  }

  public async callContactInfo(): Promise<void> {
    await this.getContact({
      eventId: this.eventId,
      acceptLanguage: this.currentEditLanguage
    });
  }

  public setContactData(): void {

    if (!this.myself) {
      return;
    }

    const contactKeys = Object.keys(this.localContact);
    contactKeys.forEach(contactKey => {
      if (this.myself[(contactKey as keyof TContact)]) {
        // @ts-ignore TODO: remove ts-ignore
        this.localContact[contactKey] = _cloneDeep(this.myself[(contactKey as keyof TContact)]);
      } else if (typeof this.localContact[(contactKey as keyof TContact)] === 'string') {
        // @ts-ignore TODO: remove ts-ignore
        this.localContact[(contactKey as keyof TContact)] = '';
      }
    });

    this.oldContact = _cloneDeep(this.localContact);

  }

  public handlePhotoUrl(payload: { image: string }): void {
    this.localContact.photo_url = payload.image || '';
    this.setContactPhotoURL(payload.image || '');
  }

  public handleFiles(payload: TFile[]): void {
    this.localContact.files = payload;
  }

  public phoneInputKeyupHandler(): void {
    this.localContact.phone = '+' + this.localContact.phone.replace(/[^\d]/g, '');
    if (this.localContact.phone === '+') {
      this.localContact.phone = '';
    }
  }

  public async onSaveClick(): Promise<void> {
    this.removeEditContactSuccessMessage();

    this.$v.localContact.$touch();
    if (this.$v.localContact.$pending || this.$v.localContact.$invalid) {
      return;
    }

    this.isLoading = true;

    await this.saveContactDataAndDisplaySuccess();

    // TODO: check can we remove this call and rely only on Vuex reactivity?
    await this.getContact({ eventId: this.eventId, acceptLanguage: this.currentEditLanguage });

    this.setContactFormSaved();
    this.isLoading = false;
  }

  public async saveContactDataAndDisplaySuccess(): Promise<void> {
    await this.saveContactDocuments(); // N.B.: must be before editContact because of side effect it has, see below
    // TODO: spaghetti side-effect — the below action reloads the contact entity, which triggers watchers etc.
    // When reloading the contact entity, contact.files get refreshed, if not saved yet.
    const editContactResponse = await this.editContact(this.getPreparedContactDataToSend());
    if (editContactResponse) {
      this.showEditContactSuccessMessage();
    }
  }

  public getPreparedContactDataToSend(): TContact {
    const contactDataToSend = _cloneDeep(this.localContact);
    delete contactDataToSend.files; // Files have to be sent separately (saveContactDocuments()) since they are an array of objects
    delete contactDataToSend.id; // API PATCH contact/info does not accept the id request parameter
    contactDataToSend.tags = this.eventContactTagsLocalSelected;
    (contactDataToSend as any).lang = this.currentEditLanguage;
    return contactDataToSend;
  }

  public async saveContactDocuments(): Promise<void> {
    const putFileRequests: Promise<void>[] = [];
    const files: TFile[] = this.localContact.files || [];

    for (let i = 0; i < files.length; i++) {
      const iteratedFile: any = (files[i] as unknown) as any;
      // If the file has id or tempUploadedFlag, then it has already been sent, no need to re-send.
      if (iteratedFile.id || (iteratedFile.tempUploadedFlag || !iteratedFile.url)) {
        continue;
      }

      putFileRequests.push(this.putContactFile({
        url: iteratedFile.url,
        filename: iteratedFile.name,
        event_id: this.eventId
      }));
    }

    await Promise.all(putFileRequests);
  }

  public setContactFormSaved(): void {
    this.oldContact = _cloneDeep(this.localContact);
    this.setHasUnsavedChanges(false);
  }

  public showEditContactSuccessMessage(): void {
    this.isEditSuccessMessageShown = true;
    setTimeout(this.removeEditContactSuccessMessage, 9000);
  }

  public removeEditContactSuccessMessage(): void {
    this.isEditSuccessMessageShown = false;
  }

  public openContactTagsSelectorPopup(): void {
    this.isContactTagSelectorVisible = true;
  }

  public closeContactTagsSelectorPopup(): void {
    this.isContactTagSelectorVisible = false;
  }

  public handleContactTagClick(contactTag: TContactTag): void {
    if ((contactTag as any).isSelected === false && this.isContactTagSelectionLimitReached) {
      return;
    }
    (contactTag as any).isSelected = !(contactTag as any).isSelected;
  }

  public async getContactTags(): Promise<void> {
    await this.$store.dispatch('_eventStore/getEventContactTags');
  }

  public clearSelectedContactTags(): void {
    this.eventContactTagsLocal = this.eventContactTagsLocal.map((item) => {
      (item as any).isSelected = false;
      return { ...item };
    });
    this.closeContactTagsSelectorPopup();
  }

  public onLanguageTabClick(newLangCode: string): void {
    const leaveAction = this.getLeaveActionFunction(newLangCode);

    if (this.selectedLanguageTab !== newLangCode && this.hasUnsavedChanges) {
      this.showConfirmLeavePopup(leaveAction);
    } else {
      leaveAction();
    }
  }

  public getLeaveActionFunction(newLangCode: string): TLeaveFunction {
    return async (): Promise<void> => {
      if (this.selectedLanguageTab === newLangCode) {
        this.selectedLanguageTab = newLangCode;
        return;
      }

      this.selectedLanguageTab = newLangCode;
      this.setHasUnsavedChanges(false);
      this.hideConfirmLeavePopup();
      let tempContactFiles: TFile[] = null;
      if (this.localContact.files) {
        tempContactFiles = _cloneDeep(this.localContact.files);
      }
      await this.getContact({
        eventId: this.eventId,
        acceptLanguage: this.currentEditLanguage
      });
      this.localContact = _cloneDeep(this.myself);
      if (tempContactFiles) {
        this.localContact.files = tempContactFiles;
      }
      this.oldContact = _cloneDeep(this.localContact);
    };
  }

  public copyStandaloneContactUrl(): void {
    window.clearTimeout(this.copyStandaloneContactTimeOut);
    this.standaloneContactTextarea.select();
    if (document.execCommand('copy')) {
      this.standaloneContactUrlCopySuccessMessage = `${this.$t('promo.company.edit.standAloneCompanyCode.copySuccess')}`;
      this.copyStandaloneContactTimeOut = window.setTimeout(() => {
        this.standaloneContactUrlCopySuccessMessage = '';
      }, 3000);
    }
  }

  @Validations()
  public validations: TVuelidateRuleSet<any> = {
    localContact: {
      name: {
        required,
      },
      surname: {
        required,
      },
      email: {
        maxLength: maxLength(250),
        email,
        required,
      },
      company_website: {
        maxLength: maxLength(250),
        url,
      },
      video_stream_url: { // TODO: is this used?
        maxLength: maxLength(250),
        url,
      },
    },
  }
}
